import { Injectable } from "@angular/core";
import { AlertController, ToastController } from "@ionic/angular";

@Injectable({
  providedIn: "root",
})
export class GlobalService {
  currentUserCellphone;
  token = undefined;
  currentUser;
  platform = "";

  typeSale = [
    {
      type: "SET",
      name: "Remate",
    },
    {
      type: "WEIGHT_AVG",
      name: "Subasta",
    },
  ];

  saleState = [
    {
      type: "FACE_TO_FACE",
      name: "Presencial",
    },
    {
      type: "VIRTUAL",
      name: "Virtual",
    },
  ];

  categorySet = [
    {
      type: "BH",
      name: "Búfalo Hembra",
    },
    {
      type: "BM",
      name: "Búfalo Macho",
    },
    {
      type: "HL",
      name: "Hembra de Levante",
    },
    {
      type: "CA",
      name: "Cabra",
    },
    {
      type: "CH",
      name: "Cerda",
    },
    {
      type: "CM",
      name: "Cerdo",
    },
    {
      type: "EH",
      name: "Equino Hembra",
    },
    {
      type: "EH",
      name: "Equino Macho",
    },
    {
      type: "HL",
      name: "Hembra de Levante",
    },
    {
      type: "HV",
      name: "Hembra de Vientre",
    },
    {
      type: "MC",
      name: "Macho de Ceba",
    },
    {
      type: "MG",
      name: "Macho Gordo",
    },
    {
      type: "ML",
      name: "Macho Levante",
    },
    {
      type: "MU",
      name: "Mular",
    },
    {
      type: "OV",
      name: "Ovinos",
    },
    {
      type: "TO",
      name: "Toro",
    },
    {
      type: "TR",
      name: "Torete",
    },
    {
      type: "VH",
      name: "Vaca Horra",
    },
    {
      type: "VP",
      name: "Vaca Parida",
    },
    {
      type: "XX",
      name: "Otros",
    },
  ];

  appPages = [];

  adminPages = [
    {
      title: "Inicio",
      url: "admin/admin-home",
      icon: "",
      src: "assets/icon/home.svg",
    },
    {
      title: "Martillo",
      url: "admin/admin-auctioneer",
      icon: "",
      src: "assets/icon/auctioneer.svg",
    },
    {
      title: "Subastas",
      url: "admin/admin-auction",
      icon: "",
      src: "assets/icon/auction.svg",
    },
    {
      title: "Histórico de ventas",
      url: "admin/admin-historic-sales",
      icon: "",
      src: "assets/icon/whiteClock.svg",
    },
    {
      title: "Subasta en vivo",
      url: "admin/admin-live-auction",
      icon: "",
      src: "assets/icon/video.svg",
    },
    {
      title: "Solicitudes Compradores",
      url: "admin/offerer-list",
      icon: "",
      src: "assets/icon/offerer.svg",
    },
    {
      title: "Solicitudes Paletas",
      url: "admin/bidder-list",
      icon: "",
      src: "assets/icon/bid.svg",
    },
    {
      title: "Publicidad",
      url: "admin/publicity",
      icon: "images-sharp",
      src: "",
    },
  ];

  offererPages = [
    {
      title: "Inicio",
      url: "offerer/offerer-home",
      icon: "",
      src: "assets/icon/home.svg",
    },
    {
      title: "Listado de lotes",
      url: "offerer/list-auction",
      icon: "",
      src: "assets/icon/auction.svg",
    },
    {
      title: "Subasta en vivo",
      url: "offerer/live-auction",
      icon: "",
      src: "assets/icon/video.svg",
    },
  ];

  auctionerPages = [
    {
      title: "Inicio",
      url: "auctioneer/auctioneer-home",
      icon: "",
      src: "assets/icon/home.svg",
    },
    {
      title: "Subastas",
      url: "auctioneer/auctioneer-auction",
      icon: "",
      src: "assets/icon/auction.svg",
    },
    {
      title: "Subasta en vivo",
      url: "auctioneer/live-auction",
      icon: "",
      src: "assets/icon/video.svg",
    },
  ];

  viewerPages = [
    {
      title: "Inicio",
      url: "viewer/viewer-home",
      icon: "",
      src: "assets/icon/home.svg",
    },
    {
      title: "Subasta en vivo",
      url: "viewer/FRECUENT_USER-live-auction",
      icon: "",
      src: "assets/icon/video.svg",
    },
  ];

  constructor(
    public toastCtrl: ToastController,
    public alertController: AlertController
  ) {}

  setPages(profie) {
    if (profie == "admin") {
      this.appPages = this.adminPages;
    }
    if (profie == "offerer") {
      this.appPages = this.offererPages;
    }
    if (profie == "auctioneer") {
      this.appPages = this.auctionerPages;
    }
    if (profie == "viewer") {
      this.appPages = this.viewerPages;
    }
  }

  async presentToast(message) {
    const toast = await this.toastCtrl.create({
      message,
      duration: 2000,
      position: "top",
    });
    toast.present();
  }

  async presentToastSuccess(message) {
    const alert = await this.alertController.create({
      message,
      keyboardClose: true,
    });
    alert.present();
    setTimeout(() => {
      alert.dismiss();
    }, 3000);
  }

  async showAlert(message) {
    const alert = await this.alertController.create({
      message,
      buttons: ["Aceptar"],
      keyboardClose: true,
    });
    alert.present();
  }
}
