import { Injectable } from '@angular/core';
import { GlobalService } from '../global.service';
import { AuthService } from '../secure/auth.service';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor(private global:GlobalService,
    private auth:AuthService) { }

  public onError(res){
    console.log(res);
    let msn = "";
    if(res.status == 404){
      msn = "Recurso no encontrado";
    }else if(res.status == 401){
      this.auth.logout();
    }
    
    else if(res.status == 204){
      if(res.error){
        if(res.error.errors){
          for(let errmsn of res.error.errors){
            msn += errmsn.defaultMessage;
          }
        }else if(res.error.message){
    
          msn = res.error.message;
        }else if(res.message){
          if(res.message.includes("Http failure response for http")){
            msn = "Error desconocido comuniquese con soporte técnico ";
          }else{
            msn = res.message
          }
        }else{
          msn = "error desconocido comuniquese con soporte tecnico";
        }
        if(msn.includes("not authorized")){
          msn = "Usuario y/o contraseña invalido";
        }
    
      }else{
        msn += "error desconocido comuniquese con soporte tecnico";
      }
     this.global.showAlert(msn);
    }else if(res.status == 0)
      this.global.showAlert("error desconocido comuniquese con soporte tecnico");
    else if(res.status == 500){
      if(res.error && res.error.message){
        msn = res.error.message;
      }else{
        msn += "error desconocido comuniquese con soporte tecnico";
      }
      this.global.showAlert(msn);
    }else {
      if(res.error && res.error.message){
        this.global.showAlert(res.error.message);
      }else {
        if(res.message.includes("Http failure response for http"))
          this.global.showAlert("error desconocido comuniquese con soporte tecnico");
        else
          this.global.showAlert(res.message);
      }
    }
   
  }


  public comonMessage(msn){
    this.global.showAlert(msn);
  }

}
