import { UUIDDateAudit } from './../base/uui-audit';
export class Profile extends UUIDDateAudit{

    identificationType:string;
    identification:string;
    cellphone:string;
    name:string;
    mail:string;
    city:string;
	department:string;
    urlPhoto:string;
    userId: string; 
    role
    dateApproved
    dateRequest
    dateRejected
}