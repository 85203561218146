import { Page } from './../../models/base/page';
import { environment } from './../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class  BaseService<T> {
  
  
  path;

  constructor(protected http: HttpClient) { }

  save(item){
    let url = environment.api+this.path;
    return this.http.put<T>(url,item);

  }

  list(params:any){
    
    let url = environment.api+this.path;
    return this.http.post<Page<T>>(url,params);
  }

  delete(id){
    let url = environment.api+this.path+"/"+id;
    return this.http.delete(url);
  }

  find(id){
    let url = environment.api+this.path+"/"+id;
    return this.http.get<T>(url);
  }

 
}

