// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  api: "https://auctionapi.sismoit.com/",
  ws: "wss://auctionapi.sismoit.com/",

  urlProductPhoto: "https://sismoit.nyc3.digitaloceanspaces.com/subagan/",
  spaceSecret: "CeAei2KUTU3Lbr6DpBKqUObw3zfT4Dh5ZFkRII1g",
  spaceToken:
    "425b2fa397284af1287478e2102127c535bcac45e5afd36dd7e6fe2eba1200a4",
  spcaeUrl: "https://us-east-1.linodeobjects.com/",
  spaceKey: "CM9YJ4MC6WTIGCFE9794",

  urlPhoto: "assets/avatar.png",

  cmouid: "",
  firebaseConfig: {
    apiKey: "AIzaSyCgqT5uXulcERHh8iTE9gUJob7cm8D1Y8U",
    authDomain: "suganar-d2a56.firebaseapp.com",
    databaseURL: "https://suganar-d2a56.firebaseio.com",
    projectId: "suganar-d2a56",
    storageBucket: "suganar-d2a56.appspot.com",
    messagingSenderId: "584964695703",
    appId: "1:584964695703:web:65f9fd0c104741f3602bd2",
    measurementId: "G-6GR3H8TD8L",
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
