import { Storage } from '@ionic/storage';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Platform } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { GlobalService } from '../global.service';

@Injectable({ providedIn: 'root' })
export class AuthService {

  authState = new BehaviorSubject(false);

  constructor(private http: HttpClient, private platform: Platform, private global: GlobalService, 
    private storage: Storage) {

      this.platform.ready().then(() => {
        this.isAuthenticated();
      });
  }

  current() {
    return this.http.get<any>(`${environment.api}user/current`).toPromise();
  }

  isAuthenticated() {
    this.storage.get('token').then((response) => {
      if (response) {
        this.global.token = response;
        this.authState.next(true);
      }
    });
  }


  otp(phone) {
    const login = {
      cellphone: phone
    };
    return this.http.post<any>(`${environment.api}auth/otp`, login);
  }


  signin(payload) {
    return this.http.post<any>(`${environment.api}auth/signin`, payload);
  }

  signup(payload) {
    return this.http.post<any>(`${environment.api}auth/signup`, payload);
  }

  logout() {
    this.global.token = undefined;
    this.storage.clear().then(() => {
      this.authState.next(false);
    });
  }

  user(cellphone) {
    return this.http.get<any>(`${environment.api}user/${cellphone}`).toPromise();
  }


  isLogged(): boolean{
    return this.authState.value;
  }

  async getUser() {
    return await this.storage.get('user');
  }

  async setUser(id) {
    return await this.storage.set('user',id);
  }
}
