import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';



@NgModule({
  declarations: [],
  imports: [IonicModule, FormsModule, CommonModule,ReactiveFormsModule], 
  exports: [IonicModule, FormsModule, CommonModule,ReactiveFormsModule]
})
export class CoreModule { }
