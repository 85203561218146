import { Page } from './../../models/base/page';
import { Profile } from './../../models/profile/profile';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '../core/base.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProfileService extends BaseService<Profile> {

  url = environment.api
  
  constructor(http:HttpClient)
  {
    super(http);
    this.path ="profile/user"
  }

  findByCellphone(cellphone)
  {
    return this.http.get<Profile>(this.url + this.path + "/find/by/cellphone/" + cellphone);
  }

  save(item) {
    let url = environment.api + this.path + "/save";
    return this.http.post<Profile>(url, item);

  }
  listProfile(dto) {
    let url = environment.api + this.path + "/list";
    return this.http.post<Page<Profile>>(url, dto);
  }


  requestOfferrer(cellphone) {
    let url = environment.api + this.path + "/request/offerer/";
    return this.http.get<Profile>(url + cellphone);
  }

  approveOfferrer(profile) {
    let url = environment.api + this.path + "/approve";
    return this.http.post<Profile>(url, profile);
  }

  rejectedOfferrer(profile) {
    let url = environment.api + this.path + "/rejected";
    return this.http.post<Profile>(url, profile);
  }
}
