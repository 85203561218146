import { Injectable } from '@angular/core';
import { HttpInterceptor } from '@angular/common/http';
import { GlobalService } from '../services/global.service';

@Injectable()
export class HttpInteceptor implements HttpInterceptor {

    constructor(  public global: GlobalService) { }

    intercept(req: import("@angular/common/http").HttpRequest<any>, next: import("@angular/common/http").HttpHandler): import("rxjs").Observable<import("@angular/common/http").HttpEvent<any>> {
        let tokenizetReq = req.clone({
            setHeaders: {
                Authorization: 'Bearer '+this.global.token
            }
        });

        return next.handle(tokenizetReq);
    }

}
