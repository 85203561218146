import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { Platform } from "@ionic/angular";
import { defineCustomElements } from "@ionic/pwa-elements/loader";
import { MessageService } from "src/app/services/core/message.service";
import { Profile } from "./models/profile/profile";
import { GlobalService } from "./services/global.service";
import { ProfileService } from "./services/profile/profile.service";
import { AuthService } from "./services/secure/auth.service";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent {
  userProfile: Profile = new Profile();
  url;

  public selectedIndex: number = 0;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public global: GlobalService,
    private router: Router,
    public auth: AuthService,
    private messageService: MessageService,
    private profileService: ProfileService
  ) {
    this.initializeApp();
  }

  logOut() {
    this.auth.logout();
    window.location.href = "login/signin";
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();

      defineCustomElements(window);
      if (
        this.platform.is("ios") ||
        this.platform.is("ipad") ||
        this.platform.is("iphone")
      ) {
        try {
          this.global.platform = "iOS";
          // load adapter.js
          var adapterVersion = "latest";
          var script = document.createElement("script");
          script.type = "text/javascript";
          script.src =
            "https://webrtc.github.io/adapter/adapter-" +
            adapterVersion +
            ".js";
          script.async = false;
          document.getElementsByTagName("head")[0].appendChild(script);
        } catch (e) {
          alert(e);
        }
      }
      this.auth.authState.subscribe((state) => {
        console.log(state);
        if (state && this.global.token) {
          this.auth.current().then(
            (id) => {
              this.auth.setUser(id);
              this.auth.user(id).then((user) => {
                this.global.currentUser = user;
                this.global.currentUser.cellphone = id;
                this.profileService.findByCellphone(id).subscribe(
                  (data) => {
                    this.global.currentUser.name = data.name;

                    if (data.urlPhoto.search("avatar.png") == -1)
                      this.global.currentUser.url =
                        data.urlPhoto.search("http") == -1
                          ? "https://" + data.urlPhoto
                          : data.urlPhoto;
                    else this.global.currentUser.url = data.urlPhoto;

                    console.log(this.global.currentUser.url, "url photo");
                  },
                  (err) => {
                    this.messageService.onError(err);
                  }
                );

                this.global.currentUser.roles.forEach((element) => {
                  if (element.name == "STAFF") {
                    this.router.navigate(["moderator/home-moderator"], {
                      replaceUrl: true,
                    });
                    this.userProfile.name = "STAFF";
                  } else if (
                    element.name == "BUYER" ||
                    element.name == "FRECUENT_USER"
                  ) {
                    this.router.navigate(["offerer/offerer-home"], {
                      replaceUrl: true,
                    });
                    this.userProfile.name = "BUYER";
                  } else if (element.name == "SUPERADMIN") {
                  /*else if (element.name == "FRECUENT_USER"){
                  this.router.navigate(['viewer/viewer-home'], {replaceUrl:true});
                  this.userProfile.name = "FRECUENT_USER";
                }*/
                    this.router.navigate(["admin/admin-home"], {
                      replaceUrl: true,
                    });
                    this.userProfile.name = "SUPERADMIN";
                  } else if (element.name == "AUCTIONEER") {
                    this.router.navigate(["auctioneer/auctioneer-home"], {
                      replaceUrl: true,
                    });
                    this.userProfile.name = "AUCTIONEER";
                  } else {
                    this.router.navigate(["viewer/viewer-home"], {
                      replaceUrl: true,
                    });
                  }
                });
              });
            },
            (err) => {
              this.messageService.onError(err);
            }
          );
        } else {
          this.router.navigate(["login/signin"]);
        }
      });
    });
  }

  profiles() {
    if (this.userProfile.name == "STAFF") {
    } else if (this.userProfile.name == "BUYER") {
      this.router.navigate(["offerer/profile"]);
    } else if (this.userProfile.name == "FRECUENT_USER") {
      this.router.navigate(["offerer/profile"]);
    } else if (this.userProfile.name == "SUPERADMIN") {
      this.router.navigate(["admin/profile"]);
    } else if (this.userProfile.name == "AUCTIONEER") {
      this.router.navigate(["auctioneer/profile"]);
    }
  }
}
