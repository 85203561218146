import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {path: 'welcome', loadChildren: () => import('./home/home.module').then( m => m.HomeModule)},
  {path: 'login', loadChildren: () => import('./user/user.module').then( m => m.UserModule)},
  {path: 'admin', loadChildren: () => import('./admin/admin.module').then( m => m.AdminModule)},
  {path: 'auctioneer', loadChildren: () => import('./auctioneer/auctioneer.module').then( m => m.AuctioneerModule)},
  {path: 'moderator', loadChildren: () => import('./moderator/moderator.module').then( m => m.ModeratorModule)},
  {path: 'auction', loadChildren: () => import('./auction/auction.module').then( m => m.AuctionModule)},
  {path: 'viewer', loadChildren: () => import('./viewer/viewer.module').then( m => m.ViewerModule)},
  {path: 'offerer', loadChildren: () => import('./offerer/offerer.module').then( m => m.OffererModule)},
  
  {
    path: '',
    redirectTo: 'welcome',
    pathMatch: 'full'
  },
   
    
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
